import { useNavBar } from "hooks/useNavBar"
import React from "react"

const WorldStoreIndex = () => {
  // Use the navbar
  useNavBar({
    hasNavBar: true,
    layoutType: "fluid",
    navbarTitle: "Home",
  })

  return (
    <div>
      {/* TODO: Create UK store switcher */}
      <p className="m-10">TODO: Switch to UK store!</p>
    </div>
  )
}

export default WorldStoreIndex
WorldStoreIndex.whyDidYouRender = true
